.grey_intro {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: none;
}
.grey_header-intro {
  max-width: 1050px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  @media (max-width: 1024px) {
    & {
      flex-direction: column;
      justify-content: center;
    }
  }
}
.grey_intro__text {
  max-width: 750px;
  width: 100%;
  position: relative;

  @media (--mediaDesktop) {
    & {
      max-width: 750px;
    }
  }
}

.grey_intro__name {
  text-transform: uppercase;
  font-size: calc((24 / 16) * 2rem);
  letter-spacing: 0.3em;
  color: var(--colorTextBody);
  margin-bottom: var(--space2XL);
  margin-top: 0;
  font-weight: var(--fontWeightMedium);
  line-height: 1;
  opacity: 0;

  @media (--mediaDesktop) {
    & {
      font-size: calc((28 / 16) * 1.5rem);
      margin-bottom: var(--space2XL);
    }
  }

  @media (--mediaTablet) {
    & {
      font-size: calc((18 / 16) * 1.5rem);
      margin-bottom: var(--space2XL);
    }
  }

  @media (--mediaMobile) {
    & {
      margin-bottom: 20px;
      letter-spacing: 0.2em;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      margin-bottom: 20px;
    }
  }
}

.grey_intro__name--entering {
  animation: fade-in var(--durationL) ease 0.2s forwards;
}

.grey_intro__name--entered {
  animation: fade-in var(--durationL) ease 0.2s forwards;
}

.scroller {
  cursor: pointer;
  transition: transform 350ms;
  display: flex;
}


@keyframes animate {
  0% {
    background-position: 0 0;
    filter: hue-rotate(0deg);
  }
  10% {
    background-position: 4px 0px;
  }
  15% {
    background-position: -8px 0px;
  }

  20% {
    background-position: -4px 0;
  }
  30% {
    background-position: 12px 0;
  }
  40% {
    background-position: -16px 0;
  }
  50% {
    background-position: -24px 0;
  }
  60% {
    background-position: 16px 0;
  }
  70% {
    background-position: 0 22px;
  }
  80% {
    background-position: -40px -10px;
  }
  100% {
    background-position: 0 0;
    filter: hue-rotate(360deg);
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes intro-scroll-indicator {
  0% {
    transform: translate3d(-1px, 0, 0);
    opacity: 0;
  }
  20% {
    transform: translate3d(-1px, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(-1px, 8px, 0);
    opacity: 0;
  }
}

.intro__scroll-indicator {
  border: 2px solid rgb(var(--rgbText) / 0.4);
  border-radius: 20px;
  width: 26px;
  height: 38px;
  position: fixed;
  bottom: 64px;
  transition-property: opacity;
  transition-duration: var(--durationL);
  transition-timing-function: ease;
  opacity: 0;

  &::before {
    content: '';
    height: 7px;
    width: 2px;
    background: rgb(var(--rgbText) / 0.4);
    border-radius: 4px;
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-1px);
  }

  @media (--mediaUseMotion) {
    & {
      transition-property: opacity, transform;
    }

    &::before {
      animation: intro-scroll-indicator 2s ease infinite;
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      display: none;
    }
  }
}

.intro__scroll-indicator--entered {
  opacity: 1;
}

.intro__scroll-indicator--hidden {
  opacity: 0;

  @media (--mediaUseMotion) {
    & {
      transform: translate3d(0, 20px, 0);
    }
  }
}

@keyframes intro-mobile-scroll-indicator {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.intro__mobile-scroll-indicator {
  position: fixed;
  bottom: 20px;
  opacity: 0;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.8, 0.1, 0.27, 1);
  transition-duration: var(--durationM);

  @media (--mediaUseMotion) {
    & {
      animation-name: intro-mobile-scroll-indicator;
      transition-property: opacity, transform;
      transform: translate3d(0, 20px, 0);
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      display: none;
    }
  }
}

.intro__mobile-scroll-indicator--entered {
  opacity: 1;
}

.intro__mobile-scroll-indicator--hidden {
  opacity: 0;
  transform: none;
}

.intro__mobile-scroll-indicator svg {
  stroke: rgb(var(--rgbText) / 0.5);
}
