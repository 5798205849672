.grey-team {
  width: 100vw;
  min-height: 100vh;
  margin-top: 60px;
  margin-bottom: 40px;
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;

  @media (--mediaTablet) {
    & {
      padding-top: 50px;
      padding-right: 80px;
      padding-left: 160px;
      height: auto;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

  @media (--mediaMobile) {
    & {
      margin-top: 0;
      padding-top: 90px;
      padding-left: 25px;
      padding-right: 25px;
      overflow-x: hidden;
    }
  }

  @media (--mediaMobile), (max-height: 696px) {
    & {
      padding-right: var(--spaceOuter);
      padding-left: var(--spaceOuter);
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      padding-right: var(--space4XL);
      padding-left: var(--space4XL);
    }
  }
}

.grey_team__description {
  padding-bottom: var(--spaceL);

  @media (--mediaUseMotion) {
    & {
      opacity: 0;
    }
  }
}

.grey_team__description--entered {
  @media (--mediaUseMotion) {
    & {
      animation: project-fade-slide 1.4s var(--bezierFastoutSlowin)
        calc(var(--initDelay) + 100ms) forwards;
    }
  }
}

.team {
  --form-height: 700px;

  position: absolute;
  display: flex;
  justify-content: center;
  padding-top: calc(50vh - var(--form-height) / 2);
  min-height: 100vh;

  @media (--mediaMobile) {
    & {
      padding-top: 0;
    }
  }
}
.team__header {
  max-width: var(--maxWidthS);
  width: 100%;
  /* padding-top: var(--space2XL); */

  @media (--mediaMobile) {
    & {
      padding-top: var(--space5XL);
      align-self: flex-start;
    }
  }
}
.team-section {
  max-width: 795px;
}
.team-section .team-header {
  display: flex;
  @media only screen and (max-width: 1040px) {
    flex-direction: column;
  }
}

.team-section .team-header .head {
  flex: 0 0 50%;
  max-width: 50%;
  @media only screen and (max-width: 1040px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.team-section .team-header .description {
  flex: 0 0 50%;
  max-width: 50%;
  @media only screen and (max-width: 1040px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 30px;
  }
}
.team-section .team-content {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 1040px) {
    flex-direction: column;
  }
}
.team-section .team-content img {
  max-width: 250px;
  min-width: 250px;
  width: 100%;
  filter: grayscale(1);
  @media only screen and (max-width: 1040px) {
    margin-right: 0;
  }
}

.team-profile {
  margin-right: 15px;
  max-width: 250px;
}

@keyframes project-fade-slide {
  0% {
    opacity: 0;
    transform: translate3d(0, var(--space3XL), 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.project__description {
  padding-bottom: var(--spaceL);

  @media (--mediaUseMotion) {
    & {
      opacity: 0;
    }
  }
}

.project__description--entered {
  @media (--mediaUseMotion) {
    & {
      animation: project-fade-slide 1.4s var(--bezierFastoutSlowin)
        calc(var(--initDelay) + 100ms) forwards;
    }
  }
}
